<template>
  <div class="RemboursementDetails" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ma demande de remboursement - {{monthRender(remboursement.month)}} {{remboursement.year}}</h1>
        <div class="form_div">
          <div class="form_content">

            <b-modal ref="modifyRemboursementModal" id="modifyRemboursementModal" hide-footer size="lg">
              <template v-slot:modal-title>
                <strong> Mettre à jour votre demande de remboursement </strong>
              </template>
              <div>
                <small v-if="remboursement.state == '2ONGOING' || remboursement.state == '4CREFUSED' || remboursement.state == '5CVALIDATED' || remboursement.state == 'DOUTE'" class="text-info">
                  <strong>Attention, votre dossier est en cours de validation.</strong>
                    Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                </small>
                <b-form>

                  <label for="modify-remboursement-children-input">Enfant(s) de moins de 4 ans concerné(s) par la garde</label>
                  <vue-multiselect-input
                    id="modify-remboursement-children-input"
                    name="modify-remboursement-children-input"
                    v-model="modifyRemboursementChildren"
                    :options="modifyRemboursementChildrenSelectOptions"
                    :multiple="true"
                    :group-select="true"
                    placeholder="Choisissez un ou plusieurs enfants"
                    selectLabel="Ajouter"
                    deselectLabel="Supprimer"
                    selectedLabel="Sélectionné"
                    track-by="value"
                    label="label">
                    <span slot="noResult">Aucun élément trouvé, veuillez réessayer</span>
                    <span slot="noOptions">Aucun enfant de moins de 4 ans</span>
                  </vue-multiselect-input>

                  <span> Type(s) de garde concerné(s) </span>
                  <b-form-group class="mt-2" id="modify-remboursement-modifyRemboursementIsCreche-group" label-for="modify-remboursement-modifyRemboursementIsCreche-input">
                    <b-form-checkbox
                      v-model="modifyRemboursementIsCreche"
                      id="modify-remboursement-modifyRemboursementIsCreche-input">
                      Crèche ou périscolaire
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group id="modify-remboursement-modifyRemboursementIsGadPajemploi-group" label-for="modify-remboursement-modifyRemboursementIsGadPajemploi-input">
                    <b-form-checkbox
                      v-model="modifyRemboursementIsGadPajemploi"
                      id="modify-remboursement-modifyRemboursementIsGadPajemploi-input">
                      Garde à domicile / Assitante maternelle via PAJEMPLOI
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group id="modify-remboursement-modifyRemboursementIsGadCesu-group" label-for="modify-remboursement-modifyRemboursementIsGadCesu-input">
                    <b-form-checkbox
                      v-model="modifyRemboursementIsGadCesu"
                      id="modify-remboursement-modifyRemboursementIsGadCesu-input">
                      Garde à domicile via CESU
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group id="modify-remboursement-modifyRemboursementIsGadStructure-group" label-for="modify-remboursement-modifyRemboursementIsGadStructure-input">
                    <b-form-checkbox
                      v-model="modifyRemboursementIsGadStructure"
                      id="modify-remboursement-modifyRemboursementIsGadStructure-input">
                      Garde à domicile via une structure de garde d'enfants / Microcrèche
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group id="modify-remboursement-modifyRemboursementIsPair-group" label-for="modify-remboursement-modifyRemboursementIsPair-input">
                    <b-form-checkbox
                      v-model="modifyRemboursementIsPair"
                      id="modify-remboursement-modifyRemboursementIsPair-input">
                        Jeune fille au pair
                    </b-form-checkbox>
                  </b-form-group>

                  <b-button
                    block :variant="modifyRemboursementButtonStyle" class="mt-2"
                    @click="modifyRemboursement"
                    :disabled="$v.modifyRemboursementChildren.$invalid || !isAtLeastOneTypeOfGarde || modifyRemboursementInProcess">
                      <b-spinner small label="modify remb Spinner" v-if="modifyRemboursementInProcess"></b-spinner> {{ modifyRemboursementButtonText }}
                  </b-button>
                </b-form>
              </div>
            </b-modal>

            <b-modal ref="modifiedRemboursementModal" id="modifiedRemboursementModal" size="lg" hide-footer title="Demande de remboursement mise à jour">
              <div class="text-center">
                <p>
                  <strong>
                    Votre demande de remboursement a bien été mise à jour.
                  </strong>
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('modifiedRemboursementModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Document Uploaded -->
            <b-modal ref="remboursementDocumentUploadedModal" size="lg" id="remboursementDocumentUploadedModal" hide-footer title="Votre document a bien été envoyé et enregistré">
              <div class="text-center">
                <p><strong>Votre document a bien été envoyé.</strong> <br>
                  Une fois tous vos documents chargés, vous pourrez demander la validation finale de votre demande de remboursement
                  auprès de votre conseiller.ère Audiens
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('remboursementDocumentUploadedModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Document Deleted -->
            <b-modal ref="documentDeletedModal" size="lg" id="documentDeletedModal" hide-footer title="Votre document a bien été supprimé">
              <div class="text-center">
                <p><strong>Votre document a bien été supprimé.</strong> <br>
                  Une fois tous vos documents chargés, vous pouvez demander la validation finale de votre demande de remboursement
                  auprès de votre conseiller.ère Audiens
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('documentDeletedModal')">Fermer</b-button>
              </div>
            </b-modal>


            <!-- Create Document -->
            <b-modal ref="createRemboursementDocumentModal" size="xl" hide-footer>
              <template v-slot:modal-title>
                <strong> Charger votre document </strong>
              </template>
              <div>
                <p>
                  <small v-if="remboursement.state === '2ONGOING' || remboursement.state === '4CREFUSED' || remboursement.state === '5CVALIDATED' || remboursement.state === 'DOUTE'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-form>
                  <b-form-group>
                      <b-form-file
                        class="text-truncate"
                        browse-text='Parcourir'
                        required
                        v-model="createRemboursementDocumentFile"
                        id="create-document-file-input"
                        placeholder="Choisissez un fichier"
                        drop-placeholder="Déposez un fichier ici"
                        @input="$v.createRemboursementDocumentFile.$touch()"
                        :state="$v.createRemboursementDocumentFile.$dirty ? !$v.createRemboursementDocumentFile.$error : null"
                      ></b-form-file>
                      <div class="form-error" v-if="!$v.createRemboursementDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
                      <div class="form-error" v-if="!$v.createRemboursementDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
                      <div class="form-error" v-if="!$v.createRemboursementDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                      <div class="form-error" v-if="!$v.createRemboursementDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                  </b-form-group>
                  <div 
                    v-if="createRemboursementDocumentType === 'TELEACTUALISATION'"
                    class="row mt-2"
                  >
                    <div class="col-12 col-md-6">
                      <b-form-group
                        id="create-document-nb_hours-input"
                        label="Nombre d'heures correspondant *"
                        label-for="create-document-nb_hours-input">
                        <b-form-input
                          type="number"
                          step="1"
                          v-model="createRemboursementDocumentNbHours"
                          id="create-document-nb_hours-input"
                          @input="$v.createRemboursementDocumentNbHours.$touch()"
                          :state="$v.createRemboursementDocumentNbHours.$dirty ? !$v.createRemboursementDocumentNbHours.$error : null"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                      <b-form-group
                        id="create-document-ccn-input"
                        label="Convention collective *"
                        label-for="create-document-ccn-input">
                        <b-form-select
                          v-model="createRemboursementDocumentCCN"
                          :options='ccnOptionsList'
                          id="create-document-ccn-input"
                          @input="$v.createRemboursementDocumentCCN.$touch()"
                          :state="$v.createRemboursementDocumentCCN.$dirty ? !$v.createRemboursementDocumentCCN.$error : null">
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  
                  <div v-if="createRemboursementDocumentType === 'TELEACTUALISATION'">
                    <p>Veuilez renseigner les périodes d'emploi liées votre document</p>
                    <div class="row">
                      <div class="col-12 col-md-5 text-center">
                        <b-form-group
                          id="periode-emplois-start-date"
                          label-cols-sm="4"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Date de début"
                          label-for="periode-emplois-start-date"
                        >
                        <b-form-datepicker
                          id="periode-emplois-start-date"
                          v-model="newPeriodeEmploiStartDate"
                          :min="minPeriodeEmploiDate"
                          :max="maxPeriodeEmploiDate"
                          placeholder="Date de début"
                        />
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-5 text-center">
                        <b-form-group
                          id="periode-emplois-end-date"
                          label-cols-sm="4"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Date de fin"
                          label-for="periode-emplois-end-date"
                        >
                          <b-form-datepicker
                            id="periode-emplois-end-date"
                            v-model="newPeriodeEmploiEndDate"
                            :min="minPeriodeEmploiDate"
                            :max="maxPeriodeEmploiDate"
                            placeholder="Date de fin"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-2 text-center">
                        <b-button
                          size="sm"
                          variant="outline-info"
                          @click="addNewPeriodeEmploi"
                        >
                          Ajouter
                        </b-button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        Périodes d'emplois choisies * :
                        <div v-if="createRemboursementDocumentPeriodeEmploi.length > 0">
                          <div
                            class="mt-2 pt-2"
                            v-for="(periode_emploi, index) in createRemboursementDocumentPeriodeEmploi" :key="index"
                          >
                            <span

                              class="p-2 mr-4 "
                              style="border-radius: 10px; background-color: #D3D3D3;"
                            >
                              {{$dayjs(periode_emploi.start_date).format('DD/MM/YYYY')}} --> {{$dayjs(periode_emploi.end_date).format('DD/MM/YYYY')}}
                              <button
                                @click="deleteNewRemboursementDocumentPeriodeEmploi(index)"
                                style="background-color: transparent !important; border: 0 !important;">
                                <v-icon name="times"/>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <strong> Veuillez renseigner au moins une période d'emploi </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-button
                    pill block :variant="createRemboursementDocumentButtonStyle" class="mt-4"
                    @click='createRemboursementDocument'
                    :disabled="createRemboursementDocumentInProcess || $v.createRemboursementDocumentFile.$invalid ||
                               $v.createRemboursementDocumentNbHours.$invalid || $v.createRemboursementDocumentCCN.$invalid ||
                               (createRemboursementDocumentType === 'TELEACTUALISATION' && createRemboursementDocumentPeriodeEmploi.length === 0)">
                      <b-spinner small label="Upload document Spinner" v-if="createRemboursementDocumentInProcess"></b-spinner> {{ createRemboursementDocumentButtonText }}
                  </b-button>
                </b-form>
              </div>
            </b-modal>

            <!-- Modify Document -->
            <b-modal ref="modifyRemboursementDocumentModal" size="xl" hide-footer>
              <template v-slot:modal-title>
                <strong> Modifier votre document </strong>
              </template>
              <div>
                <p>
                  <small v-if="remboursement.state === '2ONGOING' || remboursement.state === '4CREFUSED' || remboursement.state === '5CVALIDATED' || remboursement.state === 'DOUTE'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-form>
                  <b-form-group>
                      <label for="modify-document-file-input">
                        <strong> Choisir un nouveau document </strong>
                      </label>
                      <b-form-file
                        class="text-truncate"
                        browse-text='Parcourir'
                        required
                        v-model="modifyRemboursementDocumentFile"
                        id="modify-document-file-input"
                        placeholder="Choisissez un fichier"
                        drop-placeholder="Déposez un fichier ici"
                        @input="$v.modifyRemboursementDocumentFile.$touch()"
                        :state="$v.modifyRemboursementDocumentFile.$dirty ? !$v.modifyRemboursementDocumentFile.$error : null"
                      ></b-form-file>
                      <div class="form-error" v-if="!$v.modifyRemboursementDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
                      <div class="form-error" v-if="!$v.modifyRemboursementDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
                      <div class="form-error" v-if="!$v.modifyRemboursementDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                      <div class="form-error" v-if="!$v.modifyRemboursementDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                  </b-form-group>

                  <b-button
                    pill block :variant="modifyRemboursementDocumentButtonStyle" class="mt-2"
                    @click='modifyRemboursementDocument'
                    :disabled="modifyRemboursementDocumentInProcess || $v.modifyRemboursementDocumentFile.$invalid  ">
                      <b-spinner small label="Upload document Spinner" v-if="modifyRemboursementDocumentInProcess"></b-spinner> {{ modifyRemboursementDocumentButtonText }}
                  </b-button>
                </b-form>
                <div v-if="remboursementDocumentToModify && remboursementDocumentToModify.type === 'TELEACTUALISATION'" class="mt-4">
                  <p><strong>Périodes d'emploi liées votre document</strong></p>
                  <div class="row">
                    <div class="col-12 col-md-5 text-center">
                      <b-form-group
                        id="modify-periode-emplois-start-date"
                        label-cols-sm="4"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="8"
                        label="Date de début"
                        label-for="modify-periode-emplois-start-date"
                      >
                        <b-form-datepicker
                          id="periode-emplois-start-date"
                          v-model="modifyRemboursementDocumentNewPeriodeEmploiStartDate"
                          :min="minPeriodeEmploiDate"
                          :max="maxPeriodeEmploiDate"
                          placeholder="Date de début"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-5 text-center">
                      <b-form-group
                        id="modify-periode-emplois-end-date"
                        label-cols-sm="4"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="8"
                        label="Date de fin"
                        label-for="periode-emplois-end-date"
                      >
                        <b-form-datepicker
                          id="periode-emplois-start-date"
                          v-model="modifyRemboursementDocumentNewPeriodeEmploiEndDate"
                          :min="minPeriodeEmploiDate"
                          :max="maxPeriodeEmploiDate"
                          placeholder="Date de début"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                      <b-button
                        size="sm"
                        variant="outline-info"
                        @click="createPeriodeEmploiToRemboursementDocument"
                      >
                        Ajouter
                      </b-button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      Périodes d'emplois :
                      <div v-if="remboursementDocumentToModify.periode_emplois.length > 0">
                        <div
                          class="mt-2 pt-2"
                          v-for="(periode_emploi, index) in remboursementDocumentToModify.periode_emplois" :key="index"
                        >
                          <span

                            class="p-2 mr-4 "
                            style="border-radius: 10px; background-color: #D3D3D3;"
                          >
                            {{$dayjs(periode_emploi.start_date).format('DD/MM/YYYY')}} --> {{$dayjs(periode_emploi.end_date).format('DD/MM/YYYY')}}
                            <button
                              @click="deletePeriodeEmploi(remboursementDocumentToModify, index, periode_emploi)"
                              style="background-color: transparent !important; border: 0 !important;">
                              <v-icon name="times"/>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <strong> Veuillez renseigner au moins une période d'emploi </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>

            <!-- Modify Document -->
            <b-modal ref="deleteRemboursementDocumentModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Supprimer votre document </strong>
              </template>
              <div>
                <p>
                  Attention, si vous supprimez ce document, votre conseiller.ère Audiens ne pourrra plus y avoir accès.
                </p>
                <p>
                  <small v-if="remboursement.state === '2ONGOING' || remboursement.state === '4CREFUSED' || remboursement.state === '5CVALIDATED' || remboursement.state === 'DOUTE'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-button
                  pill block :variant="deleteRemboursementDocumentButtonStyle" class="mt-2"
                  @click='deleteRemboursementDocument'
                  :disabled="deleteRemboursementDocumentInProcess">
                    <b-spinner small label="Upload document Spinner" v-if="deleteRemboursementDocumentInProcess"></b-spinner> {{ deleteRemboursementDocumentButtonText }}
                </b-button>
              </div>
            </b-modal>

            <!-- Ask validation REMBOURSEMENT -->
            <b-modal ref="askRemboursementValidationdModal" size="lg" id="askRemboursementValidationdModal" hide-footer title="Votre demande de validation a bien été prise en compte">
              <div class="text-center">
                <p><strong>Votre demande de validation de votre dossier a bien été prise en compte</strong>.
                  Un.e conseiller.ère Audiens étudiera vos informations au plus vite </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('askRemboursementValidationdModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations de votre demande de remboursement,
                  veuillez réactualiser la page ou nous contacter directement.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <div class="row mt-4">
              <div class="col-12">
                  Sur cette page, vous pouvez charger tous les documents nécessaires à la validation de votre demande de remboursement.<br>
                  Une fois <strong>vos documents chargés</strong>  sur la plateforme, cliquez sur le bouton "Demandez la validation finale de mon remboursement"
                  pour que votre dossier soit étudié par un.e conseiller.ère Audiens.
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <strong style="font-size: 1.2em;"> Statut de votre demande de remboursement  :
                  <span v-if="remboursement.state == '1CREATED'" class="text-info" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="edit" scale="1.5"/>
                    <span class="ml-1">Documents à charger puis demande de validation à faire</span>
                  </span>
                  <span v-if="remboursement.state == '2ONGOING'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="dot-circle" scale="1.4"/>
                    <span class="ml-1">En cours de validation par votre conseiller.ère Audiens</span>
                  </span>
                  <span v-if="remboursement.state == '4CREFUSED' || remboursement.state == '5CVALIDATED' || remboursement.state === 'DOUTE'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="dot-circle" scale="1.4"/>
                    <span class="ml-1">Prise en charge par votre conseiller.ère Audiens</span>
                  </span>
                  <span v-if="remboursement.state == '3INCOMPLET'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="times-circle" scale="1.4"/>
                    <span class="ml-1">Dossier à compléter, veuillez corriger vos informations et réactualiser votre demande</span>
                  </span>
                  <span v-if="remboursement.state == '6MREFUSED'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="times-circle" scale="1.4"/>
                    <span class="ml-1">Dossier refusé</span>
                  </span>
                  <span v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'" class="text-success" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="check-circle" scale="1.4"/>
                    <span class="ml-1">Validé par votre conseiller.ère Audiens</span><br>
                  </span>
                </strong>
              </div>
            </div>

            <div v-if="remboursement.state == '2ONGOING' || remboursement.state == '4CREFUSED' || remboursement.state == '5CVALIDATED' || remboursement.state === 'DOUTE'" class="row mt-2">
              <div class="col-12">
                <strong> Vous recevrez un mail de confirmation dés traitement de votre demande </strong> <br>
                <small><em> Attention, n'oubliez pas de vérifier vos spams </em></small>
              </div>
            </div>
            <div v-if="remboursement.state == '3INCOMPLET' || remboursement.state == '6MREFUSED'" class="row mt-2">
              <div v-if="remboursement.refused_message" class="col-12">
                <strong> Message de votre conseiller.ère Audiens :</strong> <em> "{{ remboursement.refused_message}}" </em>
              </div>
              <div v-if="remboursement.refused_message_frais_deplacement" class="col-12">
                <strong> Message de votre conseiller.ère Audiens au sujet des frais supplémentaires:</strong>
                <em> "{{ remboursement.refused_message_frais_deplacement}}" </em>
              </div>
            </div>
            <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'" class="row mt-3 mb-3">
              <div class="col-12">
                <span style="font-size: 1.1em;">
                  Votre demande de remboursement pour {{monthRender(remboursement.month)}} {{remboursement.year}} <strong>a été validée.</strong><br>
                  Le montant qui vous a été alloué au titre des frais de garde est de <strong class="text-success">{{remboursement.montant}} €</strong>
                  <span v-if="remboursement.want_frais_deplacement">
                    <span v-if="remboursement.are_frais_deplacement_validated">
                      <br>Le montant qui vous a été alloué au titre des frais supplémentaires est de <strong class="text-success">{{remboursement.montant_frais_deplacement}} €</strong>
                    </span>
                    <span v-else>
                      <br>La prise en charge des frais supplémentaires a été refusée. Justification du refus : <br>
                      <em> "{{remboursement.refused_message_frais_deplacement}}"</em>
                    </span>
                  </span>
                </span>
              </div>
            </div>

            <div v-if="remboursement.state === '2ONGOING' || remboursement.state == '4CREFUSED' || remboursement.state == '5CVALIDATED' || remboursement.state === 'DOUTE' || remboursement.state == '6MREFUSED' || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'">
            </div>
            <div
              v-else-if="isAllGardeCaracteristiquesCompleted.length > 0 && (remboursement.year > 2022 || (remboursement.year <= 2022 && remboursement.month > 6))"
              class="text-center"
            >
              <h2 class="text-secondary mt-3">
                Veuillez remplir les informations demandées pour tous les modes de garde et pour chaque enfant.
                Actuellement, les modes de garde pour lesquels il manque des informations sont :
                <span
                  v-for="modeGarde in isAllGardeCaracteristiquesCompleted"
                  :key="modeGarde"
                >
                <strong class="text-danger">{{ modeDeGardeRender(modeGarde) }}</strong>,
                </span>
              </h2>
            </div>
            <div v-else-if="!isDemandeLessThan6Months && (remboursement.state === '1CREATED' || remboursement.state === '2ONGOING')">
              <h2 class="text-danger mt-3">
                Vous ne pouvez plus valider une demande concernant une date antérieure à 6 mois (ici {{monthRender(remboursement.month)}} {{remboursement.year}}).
              </h2>
            </div>
            <div v-else-if="avisPoleEmploiDocs.length === 0">
              <h2 class="text-danger mt-3">
                Veuillez charger votre téléactualisation de Pôle emploi pour le mois concerné
              </h2>
            </div>
            <div v-else-if="remboursement.is_gad_pajemploi && certPajDocs.length === 0">
              <h2 class="text-danger mt-3">
                  Votre demande concerne le mode de garde "Garde à domicile/Assistante maternelle via PAJEMPLOI",
                  veuillez charger le certificat ou revelé mensuel de PAJEMPLOI
              </h2>
            </div>
            <div v-else class="row mt-3">
              <div class="col-sm-12">
                <div v-if="isBeforeDeadline">
                  <b-button
                    block :variant="askRemboursementValidationButtonStyle" class="mt-2 font-weight-bold" size="lg"
                    @click="askRemboursementValidation"
                    :disabled="askRemboursementValidationInProcess">
                      <b-spinner small label="update EtatCivil Spinner" v-if="askRemboursementValidationInProcess"></b-spinner> {{ askRemboursementValidationButtonText }}
                  </b-button>
                  <p
                    v-if="isOneDocumentRefused"
                    class="text-danger mt-2"
                  >
                    <strong><u>Attention</u>, au moins un de vos documents a été refusé par votre conseiller.ère Audiens.</strong>
                  </p>
                </div>
                <strong
                  class="text-danger"
                  v-else
                >
                  Il n'est plus possible de faire une demande de validation 6 mois après le mois de décembre de l'année {{remboursement.year}}
                </strong>
              </div>
            </div>


            <h2>1. Votre demande de remboursement - {{monthRender(remboursement.month)}} {{remboursement.year}}</h2>
            <div v-if="canModifyDemande && isBeforeDeadline" class="row">
              <div class="col-12 text-right">
                <a href="#" @click="$bvModal.show('modifyRemboursementModal')" style="display: inline-flex; vertical-align: middle;">
                  <small>Mettre à jour votre demande</small>
                </a>
              </div>
            </div>
            <p>
              <strong> Modes de gardes concernés :</strong>
              <ul>
                <li v-if="remboursement.is_creche">
                  Crèche ou périscolaire <span v-if="isAllGardeCaracteristiquesCompleted.includes('CRECHE')" class="text-info"> - Informations à renseigner </span>
                </li>
                <li v-if="remboursement.is_gad_pajemploi">
                  Garde à domicile / Assitante maternelle via PAJEMPLOI
                  <span v-if="isAllGardeCaracteristiquesCompleted.includes('GAD_PAJEMPLOI')" class="text-info"> - Informations à renseigner </span>
                </li>
                <li v-if="remboursement.is_gad_cesu">
                  Garde à domicile via CESU
                  <span v-if="isAllGardeCaracteristiquesCompleted.includes('GAD_CESU')" class="text-info"> - Informations à renseigner </span>
                </li>
                <li v-if="remboursement.is_gad_structure">
                  Garde à domicile via une structure de garde d'enfants / Microcrèche
                  <span v-if="isAllGardeCaracteristiquesCompleted.includes('GAD_STRUCTURE')" class="text-info"> - Informations à renseigner </span>
                </li>
                <li v-if="remboursement.is_pair">
                  Jeune fille au pair
                  <span v-if="isAllGardeCaracteristiquesCompleted.includes('PAIR')" class="text-info"> - Informations à renseigner </span>
                </li>
              </ul>
            </p>

            <b-form-group v-if="canAskFraisSupplementaires">
              <label>
                <strong>
                  Avez-vous des frais supplémentaires de garde d'enfant liées à une tournée (transport, hébergement, etc.) ?
                 </strong>
               </label>
              <b-form-radio-group
                v-model="remboursement.want_frais_deplacement"
                :options="trueFalseOptions"
                :disabled="!canModifyDemande || !isBeforeDeadline"
                @change="modifyWantsFraisDeplacement($event)"
              ></b-form-radio-group>
            </b-form-group>
            <p v-if="remboursement.want_frais_deplacement">
              <v-icon class="text-info" name="info-circle" scale="1.2"/><strong> 
                Vous pourrez renseigner vos frais supplémentaires et 
                fournir vos justificatifs en bas de votre demande de remboursement dans la section créée à cet effet.
              </strong>
            </p>

            <strong> Enfants concernés : </strong>
            <b-table
              responsive class="align-middle text-center mt-4"
              headVariant="light"
              :fields="childrenRenderFields"
              :items="remboursement.children"
              >
              <template v-slot:cell(birthdate)="data">
                {{$dayjs(data.item.birthdate).format('D MMM YYYY')}}
              </template>
              <template v-slot:cell(age)="data">
                {{calculateAge(data.item.birthdate)}} ans
              </template>
            </b-table>


            <h2>2. Documents à charger</h2>

            <!-- //////////////////////// TELEACTUALISATION //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style=" margin-bottom: 0px;">
                  Téléactualisation de Pôle emploi pour le mois concerné
                  ({{monthRender(remboursement.month)}} {{remboursement.year}})
                </h3>
                <em class="text-secondary">
                  Vos justificatifs de téléactualisation de Pôle emploi pour le mois de
                  {{monthRender(remboursement.month)}} {{remboursement.year}},
                  indiquant le nombre d’heures de travail ayant nécessité la garde.
                </em><br>
                <span class="text-danger" v-if="(remboursement.beneficiaire.situation === 'MAR' || remboursement.beneficiaire.situation === 'PACS' || remboursement.beneficiaire.situation === 'VIEMAR') && remboursement.beneficiaire.is_conjoint_intermittent">
                  Vous avez déclaré un conjoint intermittent,
                  si ce conjoint est <strong>le deuxième parent</strong> de l'enfant concerné par la demande,
                  veuillez également charger ses justificatifs de téléactualisation de Pôle emploi.
                </span>

              </div>
            </div>


            <div v-if="avisPoleEmploiDocs.length > 0">
              <b-table
                responsive class="align-middle text-center mt-4 "
                headVariant="light"
                :fields="teleactualisationDocumentsRenderFields"
                :items="avisPoleEmploiDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Téléactualisation<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(nb_hours)="data">
                  <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                    <small v-if="data.item.nb_hours">{{data.item.nb_hours}} heures</small><br>
                    <small v-if="data.item.ccn">{{data.item.ccn}}</small>
                  </div>
                  <div v-else>
                    <b-input-group>
                      <b-form-input
                        size="sm" v-model="data.item.nb_hours"
                        type="number"
                        min="0" max="1000000" step="1"
                        placeholder="Nombre d'heures correspondants (facultatif)"
                        @change="setNbHoursCCNRemboursementDocument(data.item.id, data.item.nb_hours, data.item.ccn)"
                        :disabled="!isBeforeDeadline">
                      </b-form-input>
                    </b-input-group>
                    <b-input-group>
                      <b-form-select
                        size="sm"
                        v-model="data.item.ccn"
                        :options='ccnOptionsList'
                        @change="setNbHoursCCNRemboursementDocument(data.item.id, data.item.nb_hours, data.item.ccn)"
                        :disabled="!isBeforeDeadline"
                      >
                      </b-form-select>
                    </b-input-group>
                  </div>
                </template>

                <template v-slot:cell(periode_emplois)="data">
                  <div
                    class="mt-1"
                    v-for="(periode_emploi, index) in data.item.periode_emplois" :key="index"
                  >
                    <small
                      class="mt-2 p-1"
                      style="border-radius: 10px; background-color: #D3D3D3; white-space:nowrap;"
                    >
                      {{$dayjs(periode_emploi.start_date).format('DD/MM/YYYY')}} / {{$dayjs(periode_emploi.end_date).format('DD/MM/YYYY')}}
                      <button
                        @click="deletePeriodeEmploi(data.item, index, periode_emploi)"
                        style="background-color: transparent !important; border: 0 !important;">
                        <v-icon name="times"/>
                      </button>
                    </small>
                    <br>
                  </div>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                    <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Votre demande de remboursement a été refusée.</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!canModifyDemande || !isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyRemboursementDocumentModal(data.item)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!canModifyDemande || !isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteRemboursementDocumentModal(data.item)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>

              </b-table>
            </div>

            <div v-if="avisPoleEmploiDocs.length <= 20 && canModifyDemande"
                class="row mt-2"
              >
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateRemboursementDocumentModal('TELEACTUALISATION')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                </strong>
              </div>
            </div>


            <!-- //////////////////////// ATTESTATION DE PAIEMENT DE LA CAF //////////////// -->
            <div v-if="remboursement.is_creche || remboursement.is_gad_structure || remboursement.is_pair">
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Attestation de paiement de la CAF</h3>
                  <em class="text-secondary">
                    Il s'agit de l'attestation de paiement de la CAF sur la période concernée
                  </em><br>

                </div>
              </div>

              <div v-if="cafPaieDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="cafPaieDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Attestation Caf<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="
                cafPaieDocs.length <= 10 && canModifyDemande"
                class="row mt-2"
              >
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('CAFPAIE')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>


            <!-- CRECHE -->
            <div v-if="remboursement.is_creche">
              <hr>
              <h2 class="mt-4 text-info"> Crèche ou périscolaire</h2>

              <!-- //// Garde caractéristiques //// -->
              <p>
                Si vous êtes concerné.e par ces deux modes de garde,
                merci d’indiquer le total des heures de garde cumulées sur le mois, 
                le taux horaire appliqué pour la crèche et le coût mensuel pour l’accueil périscolaire.
              </p>
              <p>
                <strong>Attention</strong>, si un de vos enfants est concerné par le mode de garde "crèche" ET par le mode 
                de garde "périscolaire", veuillez cliquer sur "Ajouter" pour ajouter une ligne d'information et renseigner les informations 
                concernant l'enfant et le mode de garde.
              </p>

              <!-- On liste les garde_caracteristiques -->
              <div 
                v-for="garde_caracteristique in remboursement.garde_caracteristiques.filter(item => item.type === 'CRECHE')"
                :key="garde_caracteristique.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{garde_caracteristique.child_data.first_name}} {{garde_caracteristique.child_data.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="CRECHE"
                  :remboursement-id="remboursement.id"
                  :child="garde_caracteristique.child_data"
                  :garde-caracteristique="garde_caracteristique"
                  :isExistingGarde="true"
                  @modify-garde-information="modifyGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />
              </div>
              
              <div 
                v-for="child in filterChildWithoutGardeCaracteristiques('CRECHE')"
                :key="child.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="CRECHE"
                  :remboursement-id="remboursement.id"
                  :child="child"
                  :isExistingGarde="false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />
              </div> 

              <div class="mt-2">
                <div
                  class="row"
                >
                  <div class="col-12 col-md">
                    <b-form-group
                      id="add-creche-child-garde-car-group"
                      label-for="add-creche-child-garde-car"
                    >
                      <b-form-select
                        v-model="newChildGardeCaracteristiqueCreche"
                        id="add-creche-child-garde-car"
                        :disabled="!canModifyDemande"
                        :options='childrenSelect'>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-12 col-md-3 text-center align-self-end">
                    <b-button
                      @click="addNewGardeCaracteristiquesLine('CRECHE')"
                      :disabled="!canModifyDemande"
                      variant="outline-success"
                      size="sm"
                      pill
                      block
                      style="margin-bottom: 1rem;"
                    >
                      Ajouter une ligne d'informations
                    </b-button>
                  </div>
                </div>
              </div>

              <div 
                v-if="childGardeCaractertiqueAdd['CRECHE']" class="mt-2"
              >

                <strong> Informations concernant <u>{{childGardeCaractertiqueAdd['CRECHE'].first_name}} {{childGardeCaractertiqueAdd['CRECHE'].last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="CRECHE"
                  :remboursement-id="remboursement.id"
                  :child="childGardeCaractertiqueAdd['CRECHE']"
                  :isExistingGarde="false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />

              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Facture mensuelle</h3>
                  <em class="text-secondary">
                    Il s'agit de la facture liée à votre garde mentionnant le taux horaire ou le nombre d'heures de garde réglées
                  </em><br>

                </div>
              </div>


              <div v-if="factMensDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="factMensDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Facture mensuelle<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div
                v-if="factMensDocs.length <= 10 && canModifyDemande"
                class="row mt-2"
              >
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FACTMENS')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>



            <!-- ///////////////////////// GAD PAJEMPLOI /////////////////// -->
            <div v-if="remboursement.is_gad_pajemploi">
              <hr>
              <h2 class="mt-4 text-info"> Garde à domicile/Assistante maternelle via PAJEMPLOI</h2>


              <!-- //// Garde caractéristiques //// -->
              <div
                v-for="child in remboursement.children"
                :key="child.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="GAD_PAJEMPLOI"
                  :remboursement-id="remboursement.id"
                  :child="child"
                  :garde-caracteristique="filterGardeCaracteristiques('GAD_PAJEMPLOI', child.id)"
                  :isExistingGarde="filterGardeCaracteristiques('GAD_PAJEMPLOI', child.id) ? true : false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  @modify-garde-information="modifyGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />

              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Fiches de paie de l'employé(e)</h3>
                  <em class="text-secondary">
                    Il s'agit des fiches de paie de l'employé(e) sur la période concerné(e)
                  </em><br>

                </div>
              </div>


              <div v-if="fichePaieDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fichePaieDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Fiche de paie<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div
                v-if="fichePaieDocs.length <= 10 && canModifyDemande"
                class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FICHEPAIE')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Certificat ou relevé mensuel de PAJEMPLOI</h3>
                  <em class="text-secondary">
                    Il s'agit du certificat d'enregistrement ou du relevé mensuel de PAJEMPLOI du mois concerné
                  </em><br>

                </div>
              </div>

              <div v-if="certPajDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="certPajDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Certificat<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="certPajDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('CERTPAJ')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>





            <!-- ///////////////////////// GAD CESU /////////////////// -->
            <div v-if="remboursement.is_gad_cesu">
              <hr>
              <h2 class="mt-4 text-info"> Garde à domicile via CESU</h2>

              <!-- //// Garde caractéristiques //// -->
              <div
                v-for="child in remboursement.children"
                :key="child.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="GAD_CESU"
                  :remboursement-id="remboursement.id"
                  :child="child"
                  :garde-caracteristique="filterGardeCaracteristiques('GAD_CESU', child.id)"
                  :isExistingGarde="filterGardeCaracteristiques('GAD_CESU', child.id) ? true : false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  @modify-garde-information="modifyGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Bulletin de salaire de l'employé(e)</h3>
                  <em class="text-secondary">
                    Il s'agit du bulletin de salaire de l'employé(e) sur le mois concerné
                  </em><br>

                </div>
              </div>

              <div v-if="paieEmployeDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="paieEmployeDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Bulletin de salaire<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="paieEmployeDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('PAIEEMPLOYE')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Bordereau de cotisations sociales</h3>
                  <em class="text-secondary">
                    Il s'agit de votre bordereau de cotisations sociales sur le mois concerné
                  </em><br>

                </div>
              </div>

              <div v-if="bordDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="bordDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Bordereau<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="bordDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('BORD')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>





            <!-- ///////////////////////// GAD STRUCT /////////////////// -->
            <div v-if="remboursement.is_gad_structure">
              <hr>
              <h2 class="mt-4 text-info"> Garde à domicile via une structure de garde d'enfants / Microcrèche</h2>

              <!-- //// Garde caractéristiques //// -->
              <div
                v-for="child in remboursement.children"
                :key="child.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="GAD_STRUCTURE"
                  :remboursement-id="remboursement.id"
                  :child="child"
                  :garde-caracteristique="filterGardeCaracteristiques('GAD_STRUCTURE', child.id)"
                  :isExistingGarde="filterGardeCaracteristiques('GAD_STRUCTURE', child.id) ? true : false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  @modify-garde-information="modifyGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Facture réglée de garde</h3>
                  <em class="text-secondary">
                    Il s'agit de la facture réglée émise par la strucutre sur le mois concerné
                  </em><br>

                </div>
              </div>

              <div v-if="structFactDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="structFactDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Facture<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="structFactDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('STRUCTFACT')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>






            <!-- ///////////////////////// PAIR /////////////////// -->
            <div v-if="remboursement.is_pair">
              <hr>
              <h2 class="mt-4 text-info"> Jeune fille au pair</h2>

              <!-- //// Garde caractéristiques //// -->
              <div
                v-for="child in remboursement.children"
                :key="child.id"
                class="mt-2"
              >
                <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
                <gardeCaracteristique
                  type="PAIR"
                  :remboursement-id="remboursement.id"
                  :child="child"
                  :garde-caracteristique="filterGardeCaracteristiques('PAIR', child.id)"
                  :isExistingGarde="filterGardeCaracteristiques('PAIR', child.id) ? true : false"
                  @create-garde-information="createGardeCaracteristiques($event)"
                  @modify-garde-information="modifyGardeCaracteristiques($event)"
                  :disabled="!canModifyDemande"
                />
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Contrat de travail</h3>
                  <em class="text-secondary">
                    Il s'agit du contrat de travail de la jeune fille au pair
                  </em><br>

                </div>
              </div>


              <div v-if="contratDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="contratDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Contrat<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="contratDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('CONTRAT')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Déclaration trimestrielle de l'URSSAF</h3>
                  <em class="text-secondary">
                    Il s'agit de la déclaration trimestrielle de l'URSSAF associée et comprenant le mois concerné
                  </em><br>

                </div>
              </div>

              <div v-if="decUrssafDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="decUrssafDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Déclaration<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="decUrssafDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('DECURSSAF')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>


            <!-- //////////////////////// OTHER //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style=" margin-bottom: 0px;"> Autres justificatifs</h3>
                <em class="text-secondary">
                  Vous pouvez ajouter d'autres documents ou justificatifs à votre produit.
                </em><br>
              </div>
            </div>


            <div v-if="otherDocs.length > 0">
              <b-table
                responsive class="align-middle text-center mt-4"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="otherDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Autre<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'>
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                    <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'>
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!canModifyDemande || !isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyRemboursementDocumentModal(data.item)'>
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!canModifyDemande || !isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteRemboursementDocumentModal(data.item)'>
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="otherDocs.length <= 20 && canModifyDemande" class="row mt-2">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateRemboursementDocumentModal('OTHER')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                </strong>
              </div>
            </div>


            <!-- FRAIS DE DEPLACEMENT -->
            <div v-if="remboursement.want_frais_deplacement">
              <hr>
              <h2 class="mt-4 text-info"> Frais supplémentaires</h2>
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Contrats de travail avec la mention en tournée</h3>
                  <em class="text-secondary">
                    Il s'agit de votre ou vos contrat(s) de travail comportant la mention <u>en tournée</u>
                  </em><br>

                </div>
              </div>


              <div v-if="fdContratsTravailDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdContratsTravailDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Contrat<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdContratsTravailDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if=isBeforeDeadline
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDCONTRATS')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Fiches de paie ou AEM de la période concernée</h3>
                  <em class="text-secondary">
                    Il s'agit de vos fiches de paie ou AEM concernant la période de la demande de frais supplémentaires
                  </em><br>

                </div>
              </div>


              <div v-if="fdFichesPaieDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdFichesPaieDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Fiche ou AEM<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdFichesPaieDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDFICHEPAIES')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;"> Bulletins de salaire / Facture</h3>
                  <em class="text-secondary">
                    Il s'agit des bulletins de salaire de la garde d'enfants ou des factures de la structure de garde à domicile
                  </em><br>

                </div>
              </div>


              <div v-if="fdBulSalDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdBulSalDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Bulletin ou facture<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdBulSalDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDBULSAL')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;">Bordereau de cotisations sociales</h3>
                  <em class="text-secondary">
                    Il s'agit du bordereau de cotisations sociale de la période concernée
                  </em><br>

                </div>
              </div>


              <div v-if="fdBordereauDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdBordereauDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Bordereau<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdBordereauDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDCOTIS')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;">Attestation de paiement de la CAF</h3>
                  <em class="text-secondary">
                    Il s'agit de l'attestation de paiement de la CAF pour la période concernée
                  </em><br>

                </div>
              </div>


              <div v-if="fdAttestCafDocs.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdAttestCafDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Attestation<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdAttestCafDocs.length <= 10 && canModifyDemande" class="row mt-2">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDCAF')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;">Justificatifs des frais de transports</h3>
                  <em class="text-secondary">
                    Veuillez renseigner le montant de vos frais de transport et charger les justificatifs de vos frais de transports
                  </em><br>

                </div>
              </div>
              <div class="mt-2 frais-supplementaires">
                <div 
                  v-for="(frais, index) in fraisSupplementairesTransport"
                  :key="`frais-sup-transport-${index}`"
                  class="frais-supplementaires--item"
                >
                  <div>
                    <strong>Montant total de {{frais.montant}} €</strong>
                  </div>
                  <div 
                    v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
                    @click="deleteFraisSupplementaire(frais)"
                    class="frais-supplementaires--item--close-window" 
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
                  </div>
                </div>
                <div v-if="fraisSupplementairesTransport.length < 1" class="frais-supplementaires--add">
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="fraisSupTransportMontant"
                    placeholder="Montant total de vos frais de transports"
                  />
                  <b-button
                    variant="outline-success" size="sm" pill
                    @click="addFraisSupplementaire('TRANSPORT')"
                    :disabled="$v.fraisSupTransportMontant.$invalid || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
                  >
                    Ajouter
                  </b-button>
                </div>
              </div>
              

              <div v-if="fdJustifTrans.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdJustifTrans"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Justificatif<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdJustifTrans.length <= 10 && canModifyDemande" class="row mt-4">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDJUSTIFTRANS')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>



              <div class="row mt-3">
                <div class="col-12">
                  <h3 style=" margin-bottom: 0px;">Justificatifs des frais d'hébergement et de dîner</h3>
                  <em class="text-secondary">
                    Veuillez renseigner vos frais d'hébergements et de diner et charger les justificatifs adéquats
                  </em><br>

                </div>
              </div>

              <div class="mt-2 frais-supplementaires">
                <strong> Frais d'hébergements </strong>
                <div 
                  v-for="(frais, index) in fraisSupplementairesHebergement"
                  :key="`frais-sup-hebergements-${index}`"
                  class="frais-supplementaires--item mt-2"
                >
                  <div>
                    Le 
                    <strong>{{$dayjs(frais.date).format('DD/MM/YYYY')}}</strong> 
                    durant 
                    <strong v-if="frais.nb_nuits > 1">{{frais.nb_nuits}} nuits</strong> 
                    <strong v-else>{{frais.nb_nuits}} nuit</strong>
                    pour un montant de <strong>{{frais.montant}} €</strong>
                  </div>
                  <div 
                    v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
                    @click="deleteFraisSupplementaire(frais)"
                    class="frais-supplementaires--item--close-window" 
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
                  </div>
                </div>
                <div v-if="fraisSupplementairesHebergement.length < 31" class="frais-supplementaires--add mt-2">
                  <b-form-input
                    type="date"
                    v-model="fraisSupHebergementDate"
                    placeholder="Date"
                  />
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="fraisSupHebergementNbNuits"
                    placeholder="Nombre de nuits"
                  />
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="fraisSupHebergementMontant"
                    placeholder="Montant"
                  />
                  <b-button
                    variant="outline-success" size="sm" pill
                    @click="addFraisSupplementaire('HEBERGEMENT')"
                    :disabled="$v.fraisSupHebergementDate.$invalid || $v.fraisSupHebergementNbNuits.$invalid 
                      || $v.fraisSupHebergementMontant.$invalid 
                      || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
                  >
                    Ajouter
                  </b-button>
                </div>
              </div>

              <div class="mt-2 frais-supplementaires">
                <strong class="mt-3"> Frais de repas </strong>
                <div 
                  v-for="(frais, index) in fraisSupplementairesRepas"
                  :key="`frais-sup-repas-${index}`"
                  class="frais-supplementaires--item mt-2"
                >
                  <div>
                    Le 
                    <strong>{{$dayjs(frais.date).format('DD/MM/YYYY')}}</strong> 
                    pour un montant de <strong>{{frais.montant}} €</strong>
                  </div>
                  <div 
                    v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
                    @click="deleteFraisSupplementaire(frais)"
                    class="frais-supplementaires--item--close-window" 
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
                  </div>
                </div>
                <div v-if="fraisSupplementairesRepas.length < 15" class="frais-supplementaires--add mt-2">
                  <b-form-input
                    type="date"
                    v-model="fraisSupRepasDate"
                    placeholder="Date"
                  />
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="fraisSupRepasMontant"
                    placeholder="Montant"
                  />
                  <b-button
                    variant="outline-success" size="sm" pill
                    @click="addFraisSupplementaire('REPAS')"
                    :disabled="$v.fraisSupRepasMontant.$invalid || $v.fraisSupRepasDate.$invalid
                      || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
                  >
                    Ajouter
                  </b-button>
                </div>
              </div>


              <div v-if="fdJustifHeb.length > 0">
                <b-table
                  responsive class="align-middle text-center mt-4 "
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="fdJustifHeb"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Justificatif<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="remboursement.state == '7MVALIDATED' || remboursement.state == '6MREFUSED' || remboursement.state == '8PAIEMENT'">
                      <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                      <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary">Remboursement validé</em>
                      <em v-if="remboursement.state == '6MREFUSED'" class="text-secondary">Demande refusée</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedRemboursementDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!canModifyDemande || !isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteRemboursementDocumentModal(data.item)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="fdJustifHeb.length <= 10 && canModifyDemande" class="row mt-3">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateRemboursementDocumentModal('FDJUSTIFHEB')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{remboursement.year}}
                  </strong>
                </div>
              </div>
            </div>

            <div v-if="remboursement.state === '2ONGOING' || remboursement.state == '4CREFUSED' || remboursement.state == '5CVALIDATED' || remboursement.state === 'DOUTE' || remboursement.state == '6MREFUSED' || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'">
            </div>
            <div
              v-else-if="isAllGardeCaracteristiquesCompleted.length > 0 && (remboursement.year > 2022 || (remboursement.year <= 2022 && remboursement.month > 6))"
              class="text-center"
            >
              <h2 class="text-secondary mt-3">
                Veuillez remplir les informations demandées pour tous les modes de garde et pour chaque enfant.
                Actuellement, les modes de garde pour lesquels il manque des informations sont :
                <span
                  v-for="modeGarde in isAllGardeCaracteristiquesCompleted"
                  :key="modeGarde"
                >
                <strong class="text-danger">{{ modeDeGardeRender(modeGarde) }}</strong>,
                </span>
              </h2>
            </div>
            <div v-else-if="!isDemandeLessThan6Months && (remboursement.state === '1CREATED' || remboursement.state === '2ONGOING')">
              <h2 class="text-danger mt-3">
                Vous ne pouvez plus valider une demande concernant une date antérieure à 6 mois (ici {{monthRender(remboursement.month)}} {{remboursement.year}}).
              </h2>
            </div>
            <div v-else-if="avisPoleEmploiDocs.length === 0">
              <h2 class="text-danger mt-3">
                Veuillez charger votre téléactualisation de Pôle emploi pour le mois concerné
              </h2>
            </div>
            <div v-else-if="remboursement.is_gad_pajemploi && certPajDocs.length === 0">
              <h2 class="text-danger mt-3">
                  Votre demande concerne le mode de garde "Garde à domicile/Assistante maternelle via PAJEMPLOI",
                  veuillez charger le certificat ou revelé mensuel de PAJEMPLOI
              </h2>
            </div>
            <div v-else class="row mt-3">
              <div class="col-sm-12">
                <div v-if="isBeforeDeadline">
                  <b-button
                    block :variant="askRemboursementValidationButtonStyle" class="mt-2 font-weight-bold" size="lg"
                    @click="askRemboursementValidation"
                    :disabled="askRemboursementValidationInProcess">
                      <b-spinner small label="update EtatCivil Spinner" v-if="askRemboursementValidationInProcess"></b-spinner> {{ askRemboursementValidationButtonText }}
                  </b-button>
                  <p
                    v-if="isOneDocumentRefused"
                    class="text-danger mt-2"
                  >
                    <strong><u>Attention</u>, au moins un de vos documents a été refusé par votre conseiller.ère Audiens.</strong>
                  </p>
                </div>
                <strong
                  class="text-danger"
                  v-else
                >
                  Il n'est plus possible de faire une demande de validation 6 mois après le mois de décembre de l'année {{remboursement.year}}
                </strong>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'
import { dateOperations } from '@/mixins/utilMixins'
import { validationMixin } from 'vuelidate'
import { APIBeneficiaireConnected } from '@/api/APIBeneficiaireConnected'
import { required, minLength, maxLength, requiredIf, decimal, integer } from 'vuelidate/lib/validators'
import { fileOperations } from '@/mixins/utilMixins'
import { file_size_validation, file_extension_validation, isInteger } from '@/validators/validators'
import formTitleComponent from '@/components/formTitleComponent'
import gardeCaracteristique from '@/components/remboursement/gardeCaracteristique/gardeCaracteristique'
import { isDate } from '@/validators/validators'
import { ccnList } from '@/variables/ccn'


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiBeneficiaireConnectedService = new APIBeneficiaireConnected()

export default {
  name: 'RemboursementDetails',
  components: {
    formTitleComponent,
    gardeCaracteristique,
    Loading,
  },
  mixins: [
    renderMixins,
    fileOperations,
    validationMixin,
    dateOperations,
  ],
  data: function () {
    return {
      // ------- REBMOURSEMENT --------
      remboursement: {
        id: '',
        state: '1CREATED',
        state_changed_date: '',
        first_ask_validation_date: '',
        refused_message: '',
        refused_message_frais_deplacement: '',
        is_avis_situation_mandatory: false,
        is_teleactualisation_mandatory: false,
        is_attestation_paiement_mandatory: false,
        year: 2018,
        month: 1,
        montant:'0.00',
        justif_file: null,
        justif_file_name: '',
        is_creche: false,
        is_gad_pajemploi: false,
        is_gad_cesu: false,
        is_gad_structure: false,
        is_pair: false,
        children: [],
        frais_supplementaires: [],

        beneficiaire: {
          user: {
            first_name: '',
            last_name: '',
            username: '',
          },
          children: [],
          accords: [],
          remboursements: [],
          activite: 'ART',
          metier: '',
          other_first_names: '',
          address: '',
          code_postal: '',
          city: '',
          birthdate: '2020-01-01',
          phone_number: '',
          other_phone_number: '',
          situation: 'CELIB',
          conjoint_first_name: '',
          conjoint_last_name: '',
          is_conjoint_intermittent: false,
          muna: '',
          memo_message: '',
        },

        conseiller: {
          id: '',
          user: {
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        want_frais_deplacement: false
      },
      remboursementLoading: false,

      childrenRenderFields: [
        { key: "first_name", label: "Prénom"},
        { key: "last_name", label: "Nom"},
        { key: "birthdate", label: "Date de naissance"},
        { key: "age", label: "Age"},
      ],

      // ----- Modify remboursement ----
      modifyRemboursementChildrenSelectOptions: [],
      modifyRemboursementChildren: [],
      modifyRemboursementIsCreche: false,
      modifyRemboursementIsGadPajemploi: false,
      modifyRemboursementIsGadCesu: false,
      modifyRemboursementIsGadStructure: false,
      modifyRemboursementIsPair: false,

      modifyRemboursementButtonText: 'Mettre à jour votre demande',
      modifyRemboursementButtonStyle: 'outline-info',
      modifyRemboursementInProcess: false,

      askRemboursementValidationButtonText: "Demander la validation finale de votre dossier",
      askRemboursementValidationButtonStyle: 'outline-success',
      askRemboursementValidationInProcess: false,

      // ------ Document --------
      documentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Votre document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      teleactualisationDocumentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Votre document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "nb_hours", label: "Nombre d'heures", tdClass: 'ui-helper-center', thStyle: { width: "30%" }},
        { key: "periode_emplois", label: "Périodes d'emploi", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      ccnOptionsList: ccnList,

      handleDocumentID: 0,

      createRemboursementDocumentFile: [],
      createRemboursementDocumentNbHours: null,
      createRemboursementDocumentCCN: null,
      newPeriodeEmploiStartDate: null,
      newPeriodeEmploiEndDate: null,
      createRemboursementDocumentPeriodeEmploi: [],
      createRemboursementDocumentType: '',
      createRemboursementDocumentButtonText: 'Charger votre document',
      createRemboursementDocumentButtonStyle: 'outline-dark',
      createRemboursementDocumentInProcess: false,

      modifyRemboursementDocumentFile: [],
      remboursementDocumentToModify: null,
      modifyRemboursementDocumentNewPeriodeEmploiStartDate: null,
      modifyRemboursementDocumentNewPeriodeEmploiEndDate: null,
      modifyRemboursementDocumentButtonText: 'Modifier votre document',
      modifyRemboursementDocumentButtonStyle: 'outline-dark',
      modifyRemboursementDocumentInProcess: false,

      deleteRemboursementDocumentButtonText: 'Supprimer votre document',
      deleteRemboursementDocumentButtonStyle: 'outline-danger',
      deleteRemboursementDocumentInProcess: false,

      modifyRemboursementDocumentInformationInProcess: false,

      avisPoleEmploiDocs: [],
      otherDocs: [],

      // SI CRECHE GAD STRUC OU PAIR
      cafPaieDocs: [],

      // CRECHE
      factMensDocs: [],

      // GAD PAJEMPLOI
      fichePaieDocs: [],
      certPajDocs: [],

      // GAD CESU
      paieEmployeDocs: [],
      bordDocs: [],

      // GAD STRUC
      structFactDocs: [],

      // Pair
      contratDocs: [],
      decUrssafDocs: [],

      // Frais deplacement
      fdContratsTravailDocs: [],
      fdFichesPaieDocs: [],
      fdBulSalDocs: [],
      fdBordereauDocs: [],
      fdAttestCafDocs: [],
      fdJustifTrans: [],
      fdJustifHeb: [],

      isOneDocumentRefused: false,
      isDocumentsLoading:  false,

      trueFalseOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value:  false },
      ],

      // GARDE CARACTERISTIQUES
      newChildGardeCaracteristiqueCreche: null,
      childGardeCaractertiqueAdd: {
        'CRECHE': null
      },

      // FRAIS SUPPLEMENTAIRES
      // Transport
      fraisSupTransportMontant: null,
      
      // Hebergement
      fraisSupHebergementDate: null,
      fraisSupHebergementMontant: null,
      fraisSupHebergementNbNuits: null,
      
      // Repas 
      fraisSupRepasDate: null,
      fraisSupRepasMontant: null,
      
    }
  },
  computed: {
    canModifyDemande () {
      if (this.remboursement.state == '4CREFUSED' || this.remboursement.state == '5CVALIDATED' || this.remboursement.state == 'DOUTE'
        || this.remboursement.state == '7MVALIDATED' || this.remboursement.state == '8PAIEMENT' || this.remboursement.state == '6MREFUSED') {
        return false
      }
      else {
        return true
      }

    },
    canAskFraisSupplementaires () {
      if (this.remboursement.year > 2021) {
        return true
      }
      else if (this.remboursement.year == 2021 && this.remboursement.month >= 7) {
        return true
      }
      return false
    },
    childrenSelect () {
      return this.remboursement.children.reduce((children, item) => {
        return [
          ...children,
          {
            value: item.id,
            text: `${item.first_name} ${item.last_name}`
          }
        ]
      }, [{value: null, text: ''}])
    },
    fraisSupplementairesHebergement () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'HEBERGEMENT')
    },
    fraisSupplementairesRepas () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'REPAS')
    },
    fraisSupplementairesTransport () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'TRANSPORT')
    },
    isDemandeLessThan6Months () {
      const remboursementYear = this.remboursement.year
      const remboursementMonth = this.remboursement.month
      const today = this.$dayjs()
      const demandeDate = this.$dayjs(new Date(remboursementYear, remboursementMonth - 1, 1))
      if (today.diff(demandeDate, 'month') >= 6) {
        return false
      }
      return true
    },
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.remboursementLoading || this.isDocumentsLoading || this.createRemboursementDocumentInProcess
          || this.modifyRemboursementDocumentInProcess || this.deleteRemboursementDocumentInProcess || this.isModifyingWantsFraisDeplacement ||
          this.modifyRemboursementDocumentInformationInProcess
      ) {
        return true
      }
      return false
    },
    isAtLeastOneTypeOfGarde () {
      if (!this.modifyRemboursementIsCreche && !this.modifyRemboursementIsGadPajemploi && !this.modifyRemboursementIsGadCesu && !this.modifyRemboursementIsGadStructure && !this.modifyRemboursementIsPair) {
        return false
      }
      return true
    },
    isBeforeDeadline () {
      const remboursementYear = this.remboursement.year
      const deadline = new Date(remboursementYear + 1, 6, 30)
      const now = new Date()

      if (now < deadline || (this.remboursement.state !== '1CREATED' && this.remboursement.state !== '2ONGOING')) {
        return true
      }
      return false
    },
    modifyRemboursementChildrenIdArray () {
      var final_array = []
      for (var i = 0; i < this.modifyRemboursementChildren.length; i++) {
        final_array.push(
          this.modifyRemboursementChildren[i].value,
        )
      }
      return final_array
    },

    isAllGardeCaracteristiquesCompleted () {
      const notValidatedModeGarde = []
      if (this.remboursement.is_creche) {
        const creches_garde_caracteristiques = this.remboursement.garde_caracteristiques.filter((item) => item.type === 'CRECHE')
        if (creches_garde_caracteristiques.length < this.remboursement.children.length) {
          notValidatedModeGarde.push('CRECHE')
        }
      }
      if (this.remboursement.is_gad_pajemploi) {
        const gad_pajemploi_garde_caracteristiques = this.remboursement.garde_caracteristiques.filter((item) => item.type === 'GAD_PAJEMPLOI')
        if (gad_pajemploi_garde_caracteristiques.length < this.remboursement.children.length) {
          notValidatedModeGarde.push('GAD_PAJEMPLOI')
        }
      }
      if (this.remboursement.is_gad_cesu) {
        const gad_cesu_garde_caracteristiques = this.remboursement.garde_caracteristiques.filter((item) => item.type === 'GAD_CESU')
        if (gad_cesu_garde_caracteristiques.length < this.remboursement.children.length) {
          notValidatedModeGarde.push('GAD_CESU')
        }
      }
      if (this.remboursement.is_gad_structure) {
        const gad_structure_garde_caracteristiques = this.remboursement.garde_caracteristiques.filter((item) => item.type === 'GAD_STRUCTURE')
        if (gad_structure_garde_caracteristiques.length < this.remboursement.children.length) {
          notValidatedModeGarde.push('GAD_STRUCTURE')
        }
      }
      if (this.remboursement.is_pair) {
        const pair_garde_caracteristiques = this.remboursement.garde_caracteristiques.filter((item) => item.type === 'PAIR')
        if (pair_garde_caracteristiques.length < this.remboursement.children.length) {
          notValidatedModeGarde.push('PAIR')
        }
      }
      return notValidatedModeGarde
    },

    minPeriodeEmploiDate () {
      return new Date(this.remboursement.year, this.remboursement.month - 1, 1)
    },
    maxPeriodeEmploiDate () {
      return new Date(this.remboursement.year, this.remboursement.month, 0)

    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getRemboursement()
    this.getAllRemboursementDocuments()
  },
  validations: {
    // DOCUMENT
    createRemboursementDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
    createRemboursementDocumentNbHours: {
      required: requiredIf(function() {
        return this.createRemboursementDocumentType === 'TELEACTUALISATION'
      }),
      decimal
    },
    createRemboursementDocumentCCN: {
      required: requiredIf(function() {
        return this.createRemboursementDocumentType === 'TELEACTUALISATION'
      }),
    },
    modifyRemboursementDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
    modifyRemboursementChildren: {
      required,
      isNotEmptyArray (value) {
        if (value.length > 0) {
          return true
        }
        else {
          return false
        }
      }
    },

    // FRAIS SUPPLEMENTAIRES
    fraisSupTransportMontant: {
      required,
      decimal
    },
    fraisSupHebergementDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    fraisSupHebergementNbNuits: {
      required,
      integer
    },
    fraisSupHebergementMontant: {
      required,
      decimal
    },
    fraisSupRepasDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    fraisSupRepasMontant: {
      required,
      decimal
    },
  },
  watch: {
    remboursement: function (newRemboursement) {
      this.modifyRemboursementIsCreche = newRemboursement.is_creche
      this.modifyRemboursementIsGadPajemploi = newRemboursement.is_gad_pajemploi
      this.modifyRemboursementIsGadCesu = newRemboursement.is_gad_cesu
      this.modifyRemboursementIsGadStructure = newRemboursement.is_gad_structure
      this.modifyRemboursementIsPair = newRemboursement.is_pair

      this.modifyRemboursementChildren = []
      for (var i = 0; i < newRemboursement.children.length; i++) {
        this.modifyRemboursementChildren.push(
          {
            'label': newRemboursement.children[i].first_name + ' ' + newRemboursement.children[i].last_name,
            'value': newRemboursement.children[i].id,
          }
        )
      }

      // PRENDRE LES ENFANTS
      this.modifyRemboursementChildrenSelectOptions = []
      if (newRemboursement.beneficiaire.children.length == 0) {
        this.modifyRemboursementChildrenSelectOptions = []
      }
      else {
        var final_array = []
        for (var c = 0; c < newRemboursement.beneficiaire.children.length; c++) {
          if (newRemboursement.beneficiaire.children[c].is_active && this.calculateAge(newRemboursement.beneficiaire.children[c].birthdate) < 4) {
            final_array.push(
              {
                'label': newRemboursement.beneficiaire.children[c].first_name + ' ' + newRemboursement.beneficiaire.children[c].last_name,
                'value': newRemboursement.beneficiaire.children[c].id,
              }
            )
          }
        }
        this.modifyRemboursementChildrenSelectOptions = final_array
      }

    },
  },
  methods: {
    addFraisSupplementaire(type) {
      const data = {
        remboursement: this.remboursement.id,
        type: type
      }
      if (type === 'TRANSPORT') {
        data['montant'] = this.fraisSupTransportMontant
      }
      else if (type === 'REPAS') {
        data['date'] = this.fraisSupRepasDate
        data['montant'] = this.fraisSupRepasMontant
      }
      else if (type === 'HEBERGEMENT') {
        data['date'] = this.fraisSupHebergementDate
        data['montant'] = this.fraisSupHebergementMontant
        data['nb_nuits'] = this.fraisSupHebergementNbNuits
      }
      this.remboursementLoading = true
      apiBeneficiaireConnectedService.createFraisSupplementaire(this.token, data)
        .then((result) => {
          this.remboursement.frais_supplementaires.push(result.data)
          if (type === 'TRANSPORT') {
            this.fraisSupTransportMontant = null
          }
          else if (type === 'REPAS') {
            this.fraisSupRepasDate = null
            this.fraisSupRepasMontant = null
          }
          else if (type === 'HEBERGEMENT') {
            this.fraisSupHebergementDate = null
            this.fraisSupHebergementMontant = null
            this.fraisSupHebergementNbNuits = null
          }
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.remboursementLoading = false
        })
    },
    deleteFraisSupplementaire(fraisSupplementaire) {
      this.remboursementLoading = true
      apiBeneficiaireConnectedService.deleteFraisSupplementaire(this.token, fraisSupplementaire.id)
        .then(() => {
          this.remboursement.frais_supplementaires = this.remboursement.frais_supplementaires.filter(function (obj) {
            return obj.id !== fraisSupplementaire.id;
          });
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.remboursementLoading = false
        })
    },
    // ---------------------- GETTERS -----------------------
    getRemboursement () {
      this.remboursementLoading = true
      apiBeneficiaireConnectedService.getRemboursement(this.token, this.$route.params.remboursement_id)
        .then((result) => {
          this.remboursement = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.remboursementLoading = false
        })
    },

    // -------------------- MODIFYIERS ---------------------
    modifyRemboursement () {
      this.modifyRemboursementInProcess = true
      this.modifyRemboursementButtonText = "Mise à jour en cours"
      this.modifyRemboursementButtonStyle = 'secondary'
      apiBeneficiaireConnectedService.modifyRemboursement(
        this.token, this.$route.params.remboursement_id, this.modifyRemboursementChildrenIdArray, this.modifyRemboursementIsCreche,
        this.modifyRemboursementIsGadPajemploi, this.modifyRemboursementIsGadCesu,
        this.modifyRemboursementIsGadStructure, this.modifyRemboursementIsPair)
      .then(() => {
        this.getRemboursement()
        this.$refs.modifyRemboursementModal.hide()
        this.$refs.modifiedRemboursementModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RemboursementDetails modifyRemboursement API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementInProcess = false
        this.modifyRemboursementButtonText = "Mettre à jour votre demande"
        this.modifyRemboursementButtonStyle = 'outline-info'
      })

    },

    // -------------------- REMBOURSEMENT DOCUMENTS  --------------
    getAllRemboursementDocuments () {
      this.isDocumentsLoading = true
      this.isOneDocumentRefused = false

      this.avisPoleEmploiDocs = []
      this.otherDocs = []

      this.cafPaieDocs = []

      this.factMensDocs = []

      this.fichePaieDocs = []
      this.certPajDocs = []

      this.paieEmployeDocs = []
      this.bordDocs = []

      this.structFactDocs = []

      this.contratDocs = []
      this.decUrssafDocs = []

      this.fdContratsTravailDocs = []
      this.fdFichesPaieDocs = []
      this.fdBulSalDocs = []
      this.fdBordereauDocs = []
      this.fdAttestCafDocs = []
      this.fdJustifTrans = []
      this.fdJustifHeb = []


      apiBeneficiaireConnectedService.getAllRemboursementDocuments(this.token, this.$route.params.remboursement_id)
        .then((result) => {
          var documentsLength = result.data.length;
          for (var i = 0; i < documentsLength; i++) {
            if (result.data[i]['type'] === 'TELEACTUALISATION') {
              this.avisPoleEmploiDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'OTHER') {
              this.otherDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CAFPAIE') {
              this.cafPaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FACTMENS') {
              this.factMensDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FICHEPAIE') {
              this.fichePaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CERTPAJ') {
              this.certPajDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'PAIEEMPLOYE') {
              this.paieEmployeDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'BORD') {
              this.bordDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'STRUCTFACT') {
              this.structFactDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CONTRAT') {
              this.contratDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'DECURSSAF') {
              this.decUrssafDocs.push(result.data[i])
            }

            else if (result.data[i]['type'] === 'FDCONTRATS') {
              this.fdContratsTravailDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDFICHEPAIES') {
              this.fdFichesPaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDBULSAL') {
              this.fdBulSalDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDCOTIS') {
              this.fdBordereauDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDCAF') {
              this.fdAttestCafDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDJUSTIFTRANS') {
              this.fdJustifTrans.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDJUSTIFHEB') {
              this.fdJustifHeb.push(result.data[i])
            }

            if (result.data[i]['state'] === 'REFUSED') {
              this.isOneDocumentRefused = true
            }
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails getAllRemboursementDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.isDocumentsLoading = false
        })
    },

    getProtectedRemboursementDocument (document_id, document_name) {
      apiBeneficiaireConnectedService.getOwnProtectedRemboursementDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },


    // ------------------ DOCUMENT ---------------------

    showCreateRemboursementDocumentModal (createRemboursementDocumentType ) {
      this.createRemboursementDocumentType = createRemboursementDocumentType
      this.$refs.createRemboursementDocumentModal.show()
    },

    showModifyRemboursementDocumentModal (remboursementDocumentToModify ) {
      this.remboursementDocumentToModify = remboursementDocumentToModify
      this.$refs.modifyRemboursementDocumentModal.show()
    },

    showDeleteRemboursementDocumentModal (deleteRemboursementDocument ) {
      this.remboursementDocumentToModify = deleteRemboursementDocument
      this.$refs.deleteRemboursementDocumentModal.show()
    },


    createRemboursementDocument () {
      this.createRemboursementDocumentButtonText = "Envoi en cours"
      this.createRemboursementDocumentButtonStyle = "secondary"
      this.createRemboursementDocumentInProcess = true
      apiBeneficiaireConnectedService.createRemboursementDocument(
        this.token, this.$route.params.remboursement_id,
        this.createRemboursementDocumentType, this.createRemboursementDocumentFile,
        this.createRemboursementDocumentNbHours, this.createRemboursementDocumentCCN
      )
        .then((response) => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.remboursementDocumentUploadedModal.show()
          this.createRemboursementDocumentFile = []
          this.createRemboursementDocumentType = ''
          this.createRemboursementDocumentNbHours = null
          this.createRemboursementDocumentCCN = null
          apiBeneficiaireConnectedService.createPeriodeEmploi(
            this.token, response.data.id, this.createRemboursementDocumentPeriodeEmploi
          )
            .then(() => {
              this.getAllRemboursementDocuments()
              if (this.remboursement.state === '2ONGOING' || this.remboursement.state === '4CREFUSED' || this.remboursement.state === '5CVALIDATED' || this.remboursement.state === 'DOUTE') {
                this.getRemboursement()
              }
              this.createRemboursementDocumentPeriodeEmploi = []
              this.newPeriodeEmploiStartDate = null,
              this.newPeriodeEmploiEndDate = null
            })
            .catch((error) => {
              this.$refs.globalAPILoadingErrorModal.show()
              throw new Error('RemboursementDetails createPeriodeEmploi API Error : ' + String(error))
            })
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails createRemboursementDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.createRemboursementDocumentModal.hide()
          this.createRemboursementDocumentButtonText = "Charger votre document"
          this.createRemboursementDocumentButtonStyle = "outline-dark"
          this.createRemboursementDocumentInProcess = false
        })
    },

    modifyRemboursementDocument () {
      this.modifyRemboursementDocumentButtonText = "Envoi en cours"
      this.modifyRemboursementDocumentButtonStyle = "secondary"
      this.modifyRemboursementDocumentInProcess = true
      apiBeneficiaireConnectedService.modifyRemboursementDocument(
        this.token, this.remboursementDocumentToModify.id, this.modifyRemboursementDocumentFile)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.remboursementDocumentUploadedModal.show()
          this.modifyRemboursementDocumentFile = []
          this.remboursementDocumentToModify = null
          this.getAllRemboursementDocuments()
          if (this.remboursement.state === '2ONGOING' || this.remboursement.state === '4CREFUSED' || this.remboursement.state === '5CVALIDATED' || this.remboursement.state === 'DOUTE') {
            this.getRemboursement()
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails modifyRemboursementDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.modifyRemboursementDocumentModal.hide()
          this.modifyRemboursementDocumentButtonText = "Modifier votre document"
          this.modifyRemboursementDocumentButtonStyle = "outline-dark"
          this.modifyRemboursementDocumentInProcess = false
        })
    },

    deleteRemboursementDocument () {
      this.deleteRemboursementDocumentButtonText = "Suppression en cours"
      this.deleteRemboursementDocumentButtonStyle = "secondary"
      this.deleteRemboursementDocumentInProcess = true
      apiBeneficiaireConnectedService.deleteRemboursementDocument(
        this.token, this.remboursementDocumentToModify.id)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.remboursementDocumentToModify = null
          this.$refs.documentDeletedModal.show()
          this.getAllRemboursementDocuments()
          if (this.remboursement.state === '2ONGOING' || this.remboursement.state === '4CREFUSED' || this.remboursement.state === '5CVALIDATED' || this.remboursement.state === 'DOUTE') {
            this.getRemboursement()
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails deleteRemboursementDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.deleteRemboursementDocumentModal.hide()
          this.deleteRemboursementDocumentButtonText = "Supprimer votre document"
          this.deleteRemboursementDocumentButtonStyle = "outline-danger"
          this.deleteRemboursementDocumentInProcess = false
        })
    },

    isStringInteger (value) {
      return isInteger(value)
    },

    setNbHoursCCNRemboursementDocument (document_pk, nb_hours, ccn) {
      if (!this.isStringInteger(nb_hours) || this.modifyRemboursementDocumentInformationInProcess) {
        return null
      }
      this.modifyRemboursementDocumentInformationInProcess = true
      apiBeneficiaireConnectedService.setNbHoursCCNRemboursementDocument(
        this.token, document_pk, nb_hours, ccn)
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails setNbHoursCCNRemboursementDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.modifyRemboursementDocumentInformationInProcess = false
        })
    },



    askRemboursementValidation () {
      this.askRemboursementValidationButtonText = "Demande de validation en cours"
      this.askRemboursementValidationButtonStyle = "secondary"
      this.askRemboursementValidationInProcess = true

      apiBeneficiaireConnectedService.askRemboursementValidation(this.token, this.$route.params.remboursement_id)
        .then(() => {
          this.$refs.askRemboursementValidationdModal.show()
          this.getRemboursement()
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('RemboursementDetails askRemboursementValidation API Error : ' + String(error))
        })
        .finally(() => {
          this.askRemboursementValidationButtonText = "Demander la validation finale de votre dossier"
          this.askRemboursementValidationButtonStyle = "outline-success"
          this.askRemboursementValidationInProcess = false
        })

    },

    modifyWantsFraisDeplacement (event) {

      this.isModifyingWantsFraisDeplacement = true
      apiBeneficiaireConnectedService.modifyWantsFraisDeplacement(this.token, this.$route.params.remboursement_id, event)
        .then(() => {
          this.getRemboursement()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.isModifyingWantsFraisDeplacement = false
        })
    },

    addNewPeriodeEmploi () {
      this.createRemboursementDocumentPeriodeEmploi.push({
        'start_date': this.newPeriodeEmploiStartDate,
        'end_date': this.newPeriodeEmploiEndDate
      })
    },
    deleteNewRemboursementDocumentPeriodeEmploi (index) {
      this.createRemboursementDocumentPeriodeEmploi.splice(index, 1);
    },


    createPeriodeEmploiToRemboursementDocument() {
      this.modifyRemboursementDocumentInformationInProcess = true
      apiBeneficiaireConnectedService.createPeriodeEmploi(
        this.token, this.remboursementDocumentToModify.id,
        [
          {
            'start_date': this.modifyRemboursementDocumentNewPeriodeEmploiStartDate,
            'end_date': this.modifyRemboursementDocumentNewPeriodeEmploiEndDate,
          }
        ]
      )
      .then((response) => {
        this.remboursementDocumentToModify.periode_emplois.push(response.data[0])
        this.modifyRemboursementDocumentNewPeriodeEmploiStartDate = null,
        this.modifyRemboursementDocumentNewPeriodeEmploiEndDate = null
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RemboursementDetails createPeriodeEmploiToRemboursementDocument API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementDocumentInformationInProcess = false
      })
    },
    deletePeriodeEmploi (item, index, periode_emploi) {
      this.modifyRemboursementDocumentInformationInProcess = true
      apiBeneficiaireConnectedService.deletePeriodeEmploi(this.token, periode_emploi.id)
        .then(() => {
          item.periode_emplois.splice(index, 1);
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyRemboursementDocumentInformationInProcess = false
        })
    },

    // ------- GARDE CARACTERISTIQUES --------
    addNewGardeCaracteristiquesLine(type) {
      this.childGardeCaractertiqueAdd[type] = this.remboursement.children.find((item) => item.id == this.newChildGardeCaracteristiqueCreche)
    },
  
    filterGardeCaracteristiques(type, childId) {
      return this.remboursement.garde_caracteristiques.filter((item) => item.type === type && item.child === childId)[0]
    },

    filterChildWithoutGardeCaracteristiques(type) {
      const children_ids = this.remboursement.garde_caracteristiques.filter((item) => item.type === type).map((item) => item.child)
      return this.remboursement.children.filter((item) => !children_ids.includes(item.id))
    },

    createGardeCaracteristiques (data) {
      this.modifyRemboursementDocumentInformationInProcess = true
      apiBeneficiaireConnectedService.createGardeCaracteristiques(
        this.token, data
      )
      .then((response) => {
        this.remboursement.garde_caracteristiques.push(response.data)
        this.childGardeCaractertiqueAdd[response.data.type] = null
        this.newChildGardeCaracteristiqueCreche = null
        this.$bvToast.toast('Vos informations ont bien été enregistrées', {
          title: 'Informations enregistrées',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RemboursementDetails createGardeCaracteristiques API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementDocumentInformationInProcess = false
      })
    },

    modifyGardeCaracteristiques (data) {
      this.modifyRemboursementDocumentInformationInProcess = true
      apiBeneficiaireConnectedService.modifyGardeCaracteristiques(
        this.token, data.id, data
      )
      .then(() => {
        this.$bvToast.toast('Vos informations ont bien été enregistrées', {
          title: 'Informations enregistrées',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RemboursementDetails createGardeCaracteristiques API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementDocumentInformationInProcess = false
      })
    }
  }
}
</script>

<style>
.frais-supplementaires--item {
  display: flex;
  gap: 10px;
}
</style>